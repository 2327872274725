import React from "react";
import { MDBNavbar, MDBNavbarBrand } from "mdbreact";
import logo from "../images/logo.png";
import styled, { withTheme } from 'styled-components';


const NavbarWrapper = styled.div`
    .navbar-color {
        background-color: ${props => props.theme.navbarBackgroundColor} !important;
    }

    .logotype {
        background: url(images / logo.png) no-repeat left top;
        background-size: 100 % 100 %;
        background-position: center;
        height: 60px;
        width: 102px;
    }

      .logo-container {
        background-color: ${props => props.theme.navbarBackgroundColor} !important;
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 10px;
    }

      .logo-container.pubtrack-name, .pubtrack-divider {
        font-weight: bold;
        font-size: 1.4rem;
        color: #fff;
        display: block;
    }

      .pubtrack-divider {
        padding: 0 20px;
    }
`
const Navbar = () => {

    return (
        <NavbarWrapper>
            <MDBNavbar color="navbar-color px-5" dark expand="md">
                <MDBNavbarBrand>
                    <div className="logo-container">
                        <div className="pubtrack-name">Publication Tracker</div>
                        <div className="pubtrack-divider"> | </div>
                        <img src={logo} alt="logotype" className="logotype"></img>
                    </div>
                </MDBNavbarBrand>
            </MDBNavbar>

        </NavbarWrapper>

    );
};


export default withTheme(Navbar);
