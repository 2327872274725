import React, { useState, useEffect } from "react";
import {
    AffiliationItem,
    Loader
} from 'pubtrack-frontend-library';
import { getArticlesWithoutIdentifiers } from "../../helpers/statistics";


const Identifiers = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        getArticlesWithoutIdentifiers()
            .then(res => {
                setData(res.data)
                setLoading(false)
            });
    }, []);


    return (
        <div className="container mt-5">
            {loading ?
                <Loader />
                :
                <>
                    {data.length > 0 ?
                        <ul>
                            {data.map(item => {
                                return (
                                    <AffiliationItem key={item.name} item={item} />
                                )
                            })}
                        </ul>
                        :
                        <h4 className="text-center">No articles without identifiers</h4>
                    }
                </>
            }
        </div >
    );
};


export default Identifiers;