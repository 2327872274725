import React from "react";
import "./Login.css";
import { MDBContainer } from 'mdbreact';
import { LoginForm } from "pubtrack-frontend-library";
import Payoff from '../../components/Payoff';
import { logIn } from "../../helpers/auth";


const Login = () => (
    <>
        <Payoff />

        <MDBContainer className="form-wrapper d-flex justify-content-center">
            <LoginForm logIn={logIn} />
        </MDBContainer>
    </>
);

export default Login;