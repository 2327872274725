import React from "react";
import Routes from "./routes";
import { Footer } from "pubtrack-frontend-library";
import { ThemeProvider } from 'styled-components'
import "./App.css";
import theme from "pubtrack-frontend-library/dist/theme";
import GlobalStyle from "pubtrack-frontend-library/dist/globalStyles";

const customTheme = {
    ...theme,
     primaryColor: '#222',
    secondaryColor: '#006a82',

    tabItemColor: '#ffffff',

    accordionHeaderBackgroundColor: '#000',

    modalHeaderBackgroundColor: '#000',

    navbarBackgroundColor: '#222',
    navbarButtonHoverColor: '#080808',
    logoutButtonBackgroundColor: '#2c2a29',

    paginationButtonBackgroundColor: '#000',
    paginationButtonHoverBackgroundColor: '#000',

    tableHeaderBackgroundColor: '#000',

    searchButtonBackgroundColor: '#000',

    primaryButtonBackgroundColor: '#000',
    defaultButtonBackgroundColor: '#000',
    secondaryButtonBackgroundColor: '#222',
    colorsPalette: [
        "#77aaff", "#99ccff", "#bbeeff", "#5588ff", "#3366ff", "#038bdd", "#0079c0", "#049cdb", "#0088cc", "#0088cc"
    ]
}


const App = () => {
    return (
        <ThemeProvider theme={customTheme}>
            <GlobalStyle />
            <Routes />
            <Footer />
        </ThemeProvider>
    );
};



export default App;