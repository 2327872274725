import React, { useEffect, useState } from "react";
import { MDBCol, MDBRow } from 'mdbreact';
import {
    BarChart,
    PieChart,
    Card,
    DataTable
} from 'pubtrack-frontend-library';
import {
    getWeeklyArticles,
    getWeeklyTransactions,
    getAgreements,
    getLastMonthArticles,
    getInstitutions,
    getLastMonthTransactions,
    getArticlesPerInstitution,
    getTransactionsPerInstitution
} from "../../helpers/statistics";


const Reports = () => {
    const [weeklyTransactions, setWeeklyTransactions] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [weeklyArticles, setWeeklyArticles] = useState([]);
    const [articles, setArticles] = useState([]);
    const [institutions, setInstitutions] = useState([]);
    const [agreements, setAgreements] = useState([]);
    const [agreementsTableData, setAgreementsTableData] = useState({});
    const [articlesPerInstitution, setArticlesPerInstitution] = useState([]);
    const [articlesPerInstitutionTableData, setArticlesPerInstitutionTableData] = useState({});
    const [transactionsPerInstitution, setTransactionsPerInstitution] = useState([]);
    const [transactionsPerInstitutionTableData, setTransactionsPerInstitutionTableData] = useState({});

    useEffect(() => {
        getWeeklyTransactions()
            .then(res => setWeeklyTransactions(res.data));

        getLastMonthTransactions()
            .then(res => setTransactions(res.data));

        getTransactionsPerInstitution()
            .then(res => setTransactionsPerInstitution(res.data));

        getWeeklyArticles()
            .then(res => setWeeklyArticles(res.data));

        getLastMonthArticles()
            .then(res => setArticles(res.data));

        getArticlesPerInstitution()
            .then(res => setArticlesPerInstitution(res.data));

        getInstitutions()
            .then(res => setInstitutions(res.data));

        getAgreements()
            .then(res => setAgreements(res.data));
    }, []);

    useEffect(() => {
        setAgreementsTableData({
            columns: [
                {
                    label: 'week',
                    field: 'week',
                    attributes: {
                        width: 500,
                        'aria-controls': 'DataTable',
                        'aria-label': 'week',
                    },
                },
                {
                    label: 'prioragreement',
                    field: 'prioragreement',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'prioragreement',
                    },
                },
                {
                    label: 'no prioragreement',
                    field: 'noprioragreement',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'noprioragreement',
                    },
                },
            ],
            rows:
                agreements.map(item => {
                    return (
                        {
                            week: item.week,
                            prioragreement: item.prioragreement,
                            noprioragreement: item.noprioragreement,
                        }
                    )
                })
        });
    }, [agreements]);

    useEffect(() => {
        setArticlesPerInstitutionTableData({
            columns: [
                {
                    label: 'institution',
                    field: 'institution',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'institution',
                    },
                },
                {
                    label: 'count',
                    field: 'count',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'count',
                    },
                },
            ],
            rows:
                articlesPerInstitution.map(item => {
                    return (
                        {
                            "institution": item.institution,
                            "count": item.count,
                        }
                    )
                })
        });
    }, [articlesPerInstitution]);

    useEffect(() => {
        setTransactionsPerInstitutionTableData({
            columns: [
                {
                    label: 'institution',
                    field: 'institution',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'institution',
                    },
                },
                {
                    label: 'count',
                    field: 'count',
                    attributes: {
                        'aria-controls': 'DataTable',
                        'aria-label': 'count',
                    },
                },
            ],
            rows:
                transactionsPerInstitution.map(item => {
                    return (
                        {
                            "institution": item.institution,
                            "count": item.count,
                        }
                    )
                })
        });
    }, [transactionsPerInstitution]);


    return (
        <div className="container">
            <div>
                <MDBRow className="mt-5 d-flex">
                    <MDBCol md='6'>
                        <Card
                            title="Transactions per week:"
                            content={<BarChart data={weeklyTransactions} xAxisKey="week" yAxisKey="count" />}
                        />
                    </MDBCol>
                    <MDBCol md='6'>
                        <Card
                            title="New articles per week:"
                            content={<BarChart data={weeklyArticles} xAxisKey="week" yAxisKey="count" />}
                        />
                    </MDBCol>
                    <MDBCol md='6' className="mt-4">
                        <Card
                            title="Most active institutions:"
                            content={<PieChart data={institutions} labelKey='institution' valueKey='count' />}
                        />
                    </MDBCol>
                    <MDBCol md='6' className="mt-4">
                        <Card
                            title="Articles per institution:"
                            content={<DataTable data={articlesPerInstitutionTableData} className="mx-3" />}
                        />
                    </MDBCol>
                </MDBRow>

                <DataTable title="Prior/No prior agreements per week:" data={agreementsTableData} />
            </div>

            <div>
                <h3 className="text-center mt-4 mb-3">Statistics:</h3>
                <MDBRow className="mt-5">
                    <MDBCol md='6'>
                        <Card
                            title="Transactions per month:"
                            content={<BarChart data={transactions} xAxisKey="month" yAxisKey="count"/>}
                        />
                    </MDBCol>
                    <MDBCol md='6'>
                        <Card
                            title="New articles per month:"
                            content={<BarChart data={articles} xAxisKey="month" yAxisKey="count"/>}
                        />
                    </MDBCol>
                </MDBRow>
            </div>

            <div className="my-5">
                <h3 className="text-center">Reports:</h3>
                <DataTable title="Transactions per institution:" data={transactionsPerInstitutionTableData} />
            </div>
        </div>
    );
};


export default Reports;