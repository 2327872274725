// Here we can turn on/off items which are displayed at navbar and modal tabs by changing 'display' key

export const userSettings = {
    navbar: [
        {
            display: true,
            label: "Pipeline",
            path: "/pipeline"
        },
        {
            display: true,
            label: "Published",
            path: "/published"
        },
        {
            display: true,
            label: "Reports",
            path: "/reports"
        },
        {
            display: true,
            label: "Update identifiers",
            path: "/identifiers"
        }
    ],
    details: [
        {
            display: true,
            label: "Metadata",
        },
        {
            display: true,
            label: "JSON",
        },
        {
            display: true,
            label: "Events",
        },
        {
            display: true,
            label: "Messages",
        },
        {
            display: false,
            label: "Affiliations",
        },
    ],
};