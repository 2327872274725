import { axiosInstance } from "./axiosSetup";


export async function getWeeklyTransactions() {
    return await axiosInstance
        .get(`tracker/reports/weekly/transactions`)
        .catch(error => console.log(error))
};

export async function getLastMonthTransactions() {
    return await axiosInstance
        .get(`tracker/reports/lastmonth/transactions`)
        .catch(error => console.log(error))
};

export async function getTransactionsPerInstitution() {
    return await axiosInstance
        .get(`tracker/reports/institutions/transactions`)
        .catch(error => console.log(error))
};


export async function getWeeklyArticles() {
    return await axiosInstance
        .get(`tracker/reports/weekly/articles`)
        .catch(error => console.log(error))
};

export async function getLastMonthArticles() {
    return await axiosInstance
        .get(`tracker/reports/lastmonth/articles`)
        .catch(error => console.log(error))
};

export async function getArticlesWithoutIdentifiers() {
    return await axiosInstance
        .get(`tracker/reports/articles/withoutidentifiers`)
        .catch(error => console.log(error))
};

export async function getArticlesInProduction() {
    return await axiosInstance
        .get(`tracker/reports/articles/production`)
        .catch(error => console.log(error))
};

export async function getArticlesPerInstitution() {
    return await axiosInstance
        .get(`tracker/reports/institutions/articles`)
        .catch(error => console.log(error))
};


export async function getInstitutions() {
    return await axiosInstance
        .get(`tracker/reports/weekly/institutions`)
        .catch(error => console.log(error))
};

export async function getAgreements() {
    return await axiosInstance
        .get(`tracker/reports/weekly/agreements`)
        .catch(error => console.log(error))
};
