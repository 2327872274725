import React, { useState, useEffect } from "react";
import { getArticles, getArticleDetails } from "../../helpers/articles";
import { itemsPerPage } from "../../constants";
import {
    ArticleDetailsModal,
    CustomTable
} from "pubtrack-frontend-library";
import { userSettings } from "../../user-settings";


const Pipeline = () => {
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [articleDetails, setArticleDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const columns = React.useMemo(
        () => [
            {
                Header: 'manuscript ID',
                accessor: 'manuscript_id',
                sortable: true
            },
            {
                Header: 'DOI',
                accessor: 'doi',
                sortable: true
            },
            {
                Header: 'journal title',
                accessor: 'journal',
                sortable: true
            },
            {
                Header: 'manuscript title',
                accessor: 'manuscript_title',
                sortable: true
            },
            {
                Header: 'last event',
                accessor: 'lastevent',
                sortable: false
            }
        ], []
    );

    const getArticlesData = ({ searchValue, offset, itemsPerPage, sortBy, sortDir }) => {
        return getArticles(searchValue, offset, itemsPerPage, sortBy, sortDir, "production")
            .then(res => ({ data: res.data.articles, total: res.data.total }) )
    }

    const onSelectRow = row => {
        const messageId = row && row.original ? row.original["id"] : null;
        setSelectedMessage(messageId);
    };

    useEffect(() => {
        if (selectedMessage) {
            getArticleDetails(selectedMessage)
                .then(res => setArticleDetails(res.data))
                .then(setIsOpen(true))
                .catch(error => console.log(error))
                .then(setSelectedMessage(null))
        }
    }, [selectedMessage]);

    return (
        <div className="container">
             <CustomTable
                columns={columns}
                itemsPerPage={itemsPerPage}
                getTableData={getArticlesData}
                onSelectRow={onSelectRow}
            />
            <ArticleDetailsModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                articleDetails={articleDetails}
                tabItems={userSettings.details}
            />
        </div>
    );
};


export default Pipeline;