import { axiosInstance } from "./axiosSetup";


export async function getArticles(searchValue, offset, perPage, sortBy, sortDir, selection) {
    return await axiosInstance
        .get(`tracker/articles?query=${searchValue}&startrow=${offset}&maxrows=${perPage}&orderby=${sortBy}&orderdir=${sortDir}&selection=${selection}`)
        .catch(error => console.log(error))
};

export async function getArticleDetails(selectedMessage) {
    return await axiosInstance
        .get(`tracker/article/${selectedMessage}`)
        .catch(error => console.log(error))
};
