import axios from 'axios'
import { URL, AUTH_URL } from '../constants'
import { token as TOKEN } from 'pubtrack-frontend-library'

const configureAxiosInstances = (urls) => {
  return urls.map((url) => {
    const axiosInstance = axios.create({
      baseURL: url,
    })
    axiosInstance.interceptors.request.use((config) => {
      const token = localStorage.getItem(TOKEN)
      config.headers.Authorization = `Bearer ${token}`
      config.headers['Content-Type'] = 'application/json'
      return config
    })
    return axiosInstance
  })
}

const [axiosInstance, axiosAuthInstance] = configureAxiosInstances([
  URL,
  AUTH_URL,
])

export { axiosInstance, axiosAuthInstance }
